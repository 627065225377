import type {Site} from '@data/types';
import {useTranslations} from '@/hooks/useTranslations';

const getCountryCode = (site: Site) => {
  if (site.domain === 'zh.shopify.com') {
    return 'CN';
  }

  return site.countryCode;
};

export const useBlogPageTitle = (title: string): string => {
  const {t, site} = useTranslations();
  const countryCode = getCountryCode(site);

  const brand = t('global:shopify', {defaultValue: 'Shopify'});

  if (countryCode.toLowerCase() === 'us') {
    return `${title} - ${brand}`;
  }

  const country = t(`global:countries.${countryCode.toLowerCase()}`);

  return `${title} - ${brand} ${country}`;
};
